import React from "react"
import { PropertyControls, ControlType } from "framer"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default class Code_OpenCookies extends React.Component {
    // Define the default properties
    static defaultProps = {
        text: "Cookies",
    }

    // Define property controls using Framer
    static propertyControls: PropertyControls = {
        text: { type: ControlType.String, title: "Text" },
    }

    render() {
        // Styles for the link
        const linkStyle = {
            fontFamily: "inherit", // Inherit font family
            fontSize: "inherit", // Inherit font size
            fontWeight: "inherit", // Inherit font weight
            color: "inherit", // Inherit color
            textDecoration: "underline",
            cursor: "pointer",
        }

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "inherit",
                    fontSize: "inherit",
                    fontFamily: "inherit",
                    fontWeight: "inherit",
                }}
            >
                <a
                    href="#cookie-settings"
                    data-cc="c-settings"
                    style={linkStyle}
                >
                    {this.props.text}
                </a>
            </div>
        )
    }
}
